import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const License = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="text-center md:text-start mb-10">
          <h1 className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
            Source Code <span className="text-[#F9A200]">License.</span>
          </h1>
          <p className="text-xl mt-4">
            This document outlines the terms under which the source code developed by Ashita
            Creatives is licensed to our clients or users.
          </p>
        </div>

        <div className="space-y-10">
          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">1. Grant of License</h2>
            <p className="text-lg">
              Upon full payment and project completion, Ashita Creatives grants you a non-exclusive,
              non-transferable, and perpetual license to use the source code for the purposes
              outlined in the project agreement.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">2. Permitted Uses</h2>
            <p className="text-lg">You are allowed to:</p>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>Use the source code for the agreed project or product.</li>
              <li>
                Modify the source code for personal or commercial use within the scope of the
                agreement.
              </li>
              <li>Deploy the source code to production or staging environments.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">3. Restrictions</h2>
            <p className="text-lg">
              Unless explicitly agreed in writing, you are not permitted to:
            </p>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>Redistribute or resell the source code as-is or in a modified form.</li>
              <li>Claim ownership of the original source code developed by Ashita Creatives.</li>
              <li>Use the source code for purposes outside the scope of the agreed project.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">4. Ownership</h2>
            <p className="text-lg">
              Ashita Creatives retains ownership of the intellectual property of the source code
              until full payment is received. Upon payment, ownership rights are transferred as per
              the terms outlined in the project agreement.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">5. Warranty Disclaimer</h2>
            <p className="text-lg">
              The source code is provided "as-is" without any warranties of any kind, either
              expressed or implied, including but not limited to warranties of merchantability,
              fitness for a particular purpose, or non-infringement.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">6. Indemnification</h2>
            <p className="text-lg">
              You agree to indemnify and hold harmless Ashita Creatives from any claims, damages, or
              liabilities resulting from the use, modification, or deployment of the source code.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">
              7. Limitation of Liability
            </h2>
            <p className="text-lg">
              In no event shall Ashita Creatives be held liable for any damages arising from the use
              or inability to use the source code, including incidental, consequential, or special
              damages.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">
              8. Updates and Maintenance
            </h2>
            <p className="text-lg">
              Any updates, upgrades, or maintenance of the source code are subject to a separate
              agreement and are not covered under this license unless explicitly mentioned.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">9. Governing Law</h2>
            <p className="text-lg">
              This license is governed by the laws of Uganda. Any disputes shall be resolved in the
              courts of Uganda.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">
              10. Contact Information
            </h2>
            <p className="text-lg">For any questions regarding this license, please contact:</p>
            <p className="text-lg">
              Email:{' '}
              <a href="mailto:ashitacreatives@gmail.com" className="text-[#F9A200] hover:underline">
                ashitacreatives@gmail.com
              </a>{' '}
              <br />
              Phone: +256 782 692271 <br />
            </p>

            <div className="mt-12">Last Updated: 26th November, 2024</div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
