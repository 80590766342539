import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="text-center md:text-start mb-10">
          <h1 className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
            Terms of <span className="text-[#F9A200]">Service.</span>
          </h1>
          <p className="text-xl mt-4">
            Welcome to Ashita Creatives. Please read these terms of service carefully before using
            our website or services.
          </p>
        </div>

        <div className="space-y-10">
          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">1. Acceptance of Terms</h2>
            <p className="text-lg">
              By accessing or using our services, you agree to be bound by these terms of service
              and our privacy policy. If you do not agree, please discontinue use of our services.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">2. Services Provided</h2>
            <p className="text-lg">
              Ashita Creatives offers various IT-related services, including but not limited to:
            </p>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>Quick Custom Websites</li>
              <li>UI Design & Prototype</li>
              <li>Mobile App Development</li>
              <li>Web App Development</li>
              <li>Full Online Platforms</li>
              <li>Coding Classes</li>
              <li>Design Classes</li>
              <li>Tech Law Advisory</li>
            </ul>
            <p className="text-lg mt-4">
              The specifics of each service will be outlined in individual contracts or agreements.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">
              3. User Responsibilities
            </h2>
            <p className="text-lg">When using our services, you agree to:</p>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>Provide accurate and up-to-date information.</li>
              <li>Not use our services for illegal or unauthorized purposes.</li>
              <li>Comply with all applicable laws and regulations.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">4. Payment Terms</h2>
            <p className="text-lg">
              All payments for services must be made according to the agreed schedule. Late payments
              may result in service interruptions or additional fees.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">
              5. Intellectual Property
            </h2>
            <p className="text-lg">
              All content, designs, code, and intellectual property created by Ashita Creatives
              remain the property of Ashita Creatives until full payment is received. Upon payment,
              ownership will transfer to the client as agreed.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">
              6. Limitation of Liability
            </h2>
            <p className="text-lg">
              Ashita Creatives is not liable for any indirect, incidental, or consequential damages
              arising from the use or inability to use our services. Our total liability is limited
              to the amount paid for the specific service in question.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">7. Termination</h2>
            <p className="text-lg">
              We reserve the right to terminate or suspend access to our services at any time for
              violations of these terms or other legitimate reasons.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">8. Changes to Terms</h2>
            <p className="text-lg">
              Ashita Creatives may modify these terms at any time. Continued use of our services
              after modifications constitute acceptance of the updated terms.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">9. Governing Law</h2>
            <p className="text-lg">
              These terms are governed by the laws of Uganda. Any disputes will be resolved in
              Ugandan courts.
            </p>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">10. Contact Us</h2>
            <p className="text-lg">For questions about these terms, contact us at:</p>
            <p className="text-lg">
              Email:{' '}
              <a href="mailto:ashitacreatives@gmail.com" className="text-[#F9A200] hover:underline">
                ashitacreatives@gmail.com
              </a>{' '}
              <br />
              Phone: +256 782 692271 <br />
            </p>

            <div className="mt-12">Last Updated: 26th November, 2024</div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
