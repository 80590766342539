import React from 'react';
import FooterBg from 'assets/footer-bg.png';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="">
      <div
        className=""
        style={{
          backgroundImage: `url('${FooterBg}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="mx-4 pt-16 md:mx-56 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="mb-8">
            <div className="text-4xl league-spartan-extra-bold mb-3">ABOUT ASHITA</div>
            <div className="mb-3 text-sm">
              Ashita is a design driven software, web & mobile development company. We run a couple
              of teams remotely which also include a Product Manager to coordinate the team during
              development.
            </div>
            <div className="text-sm">
              Ashita is based in Uganda, Kampala however we build and maintain systems for clients
              from any part of the world.
            </div>
          </div>

          <div className="mb-8">
            <div className="text-4xl league-spartan-extra-bold mb-3">RESOURCES</div>
            <ul className="mx-8 list-disc list-outside">
              <li className="mb-1">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>

              <li className="mb-1">
                <Link to="/terms-of-service">Terms of Service</Link>
              </li>

              <li className="mb-1">
                <Link to="/license">License</Link>
              </li>

              <li className="mb-1">
                <Link to="/sitemap">Sitemap</Link>
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <div className="text-4xl league-spartan-extra-bold mb-3">REACH OUT</div>
            <div className="mb-3">
              <div className="league-spartan-extra-bold">Email</div>
              <div className="">ashitacreatives@gmail.com</div>
            </div>

            <div className="mb-3">
              <div className="league-spartan-extra-bold">Operations</div>
              <div className="">+256 773 558 907</div>
            </div>

            <div className="mb-3">
              <div className="league-spartan-extra-bold">Development</div>
              <div className="">+256 782 692 271</div>
            </div>
          </div>
        </div>
        <div className="mx-0 md:mx-56 bg-black text-white p-4 grid grid-cols-1 md:grid-cols-2 gap-4 justify-between">
          <div className="">Copyright Ashita Creatives, 2024 All Rights Reserved</div>
          <div className="flex flex-row justify-end">
            <Link to="/privacy-policy">Privacy Policy</Link> .{' '}
            <Link to="/terms-of-service">Terms of Service</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
