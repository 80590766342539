import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import { MdClose } from 'react-icons/md';
import { Menu } from 'react-feather';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const LinkItems = [
    { title: 'Home', link: '/' },
    { title: 'Our Products', link: '/our-products' },
    { title: 'About Us', link: '/about-us' },
    { title: 'Contact Us', link: '/contact-us' },
  ];

  const LinkItem = ({ title, link }) => {
    const isActive = location.pathname === link;
    return (
      <Link
        to={link}
        className={`my-1 text-lg font-medium md:mx-4 md:my-0 hover:text-[#F9A200] ${
          isActive ? 'league-spartan-extra-bold text-[#F9A200]' : 'text-gray-700'
        }`}
      >
        {title}
      </Link>
    );
  };

  return (
    <nav className="px-3 md:px-42 py-2 md:py-8">
      <div className="flex flex-col md:flex-row justify-between md:justify-center md:items-center">
        <div className="flex justify-between space-x-8 items-center">
          <div>
            <Logo />
          </div>
          <div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none md:hidden"
            >
              {isOpen ? (
                <MdClose className="h-6 w-6 fill-current" />
              ) : (
                <Menu className="h-6 w-6 fill-current" />
              )}
            </button>
          </div>
        </div>

        <div className={`md:flex items-center ${isOpen ? '' : 'hidden'}`}>
          <div className="flex flex-col items-center md:space-x-10 md:flex-row md:mx-6">
            {LinkItems.map((item, index) => (
              <LinkItem key={index} title={item.title} link={item.link} />
            ))}
            <div className="flex flex-col items-center md:space-x-10 md:flex-row md:mx-6">
              <Link
                to="/console"
                className="league-spartan-extra-bold bg-black text-xl text-primary-100 p-1 px-12 font-bold border-b-8 border-primary-100"
              >
                Console
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
