import { WebLayout } from 'components/Layout';
import React from 'react';
import FeaturesImg from 'assets/features.svg';
import ApiIntegrationImg from 'assets/api-integration.svg';

export const Console = () => {
  return (
    <WebLayout>
      <div className="mb-2">
        <div className="my-6 flex flex-col items-center justify-center">
          <div className="text-3xl md:text-7xl league-spartan-extra-bold text-center">Console</div>
          <div className="ml-36">
            <div className="bg-black text-[#F9A200] px-3 w-fit">Coming Soon</div>
          </div>
        </div>
        <div className="text-center text-3xl font-bold my-3">
          Our console is still under development.
        </div>
        <div className="text-xl md:w-1/2 text-center mx-auto p-4">
          We are building a platform to help our clients manage their projects efficiently. Our
          console provides payment reminders, project progress daily & weekly updates to the client
          and project collaborators. Clients can add collaborators to contribute to the development
          process by providing their feedback on certain project features.
        </div>
      </div>
      <div className="mb-12 mx-8 md:mx-64 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
        <div className="">
          <div className="bg-black text-[#F9A200] px-3 w-fit">Features</div>
          <div className="text-3xl league-spartan-extra-bold my-3">
            Check out the console beta features for{' '}
            <span className="text-[#F9A200]">May Launch.</span>
          </div>
          <div className="text-xl my-3">
            The console will contain features that serve the client & also help Ashita manage
            clients not limited to the following;
          </div>
          <ul className="ml-6 text-xl list-disc list-outside">
            <li className="mb-1">Project timelines & milestones</li>
            <li className="mb-1">Contract generation & signing</li>
            <li className="mb-1">Invoicing, receipts & quotation</li>
            <li className="mb-1">Domain management</li>
          </ul>
        </div>

        <div className="flex flex-row items-end justify-end">
          <img src={FeaturesImg} alt="" className="h-48" />
        </div>
      </div>

      <div className="mx-8 md:mx-64 grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-24">
        <div className="flex flex-row items-start justify-start">
          <img src={ApiIntegrationImg} alt="" className="h-64" />
        </div>
        <div className="">
          <div className="bg-black text-[#F9A200] px-3 w-fit">API Integration</div>
          <div className="text-3xl league-spartan-extra-bold my-3">
            Integration with Ashita’s API and subscribe to any of our services.
          </div>
          <div className="text-xl mb-3">
            The Ashita API will provide integration for most of it’s services. Companies can connect
            to our API to utilize the following;
          </div>
          <ul className="ml-6 text-xl list-disc list-outside">
            <li className="mb-1">Project Management</li>
            <li className="mb-1">Contract Generation & Signing</li>
            <li className="mb-1">Invoicing, receipts & quotation generation</li>
            <li className="mb-1">Domain name reservation</li>
            <li className="mb-1">Employee Management</li>
          </ul>
        </div>
      </div>

      <div className="bg-black py-20">
        <div className="mx-8 md:mx-64">
          <div className="text-white w-2/3 text-xl league-spartan-extra-bold mb-5">
            The API will be available for integration latest by 18th May, 2025. Join the waiting
            list and be the first to know.
          </div>
          <div className="">
            <button className="text-white border-b-4 league-spartan-extra-bold text-lg border-white bg-[#F9A200] p-2 px-12 py-3">
              Join Waitlist
            </button>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
