import { WebLayout } from 'components/Layout';
import React from 'react';
import { Link } from 'react-router-dom';

export const SiteMap = () => {
  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="text-center md:text-start mb-10">
          <h1 className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
            Website <span className="text-[#F9A200]">Sitemap.</span>
          </h1>
          <p className="text-xl mt-4">
            Explore the structure of our website to find the information and services you need.
          </p>
        </div>

        <div className="space-y-10">
          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">Main Pages</h2>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>
                <a href="/" className="text-[#F9A200] hover:underline">
                  Home
                </a>
              </li>
              <li>
                <Link to="/our-products" className="text-[#F9A200] hover:underline">
                  Our Products
                </Link>
              </li>
              <li>
                <Link to="/about-us" className="text-[#F9A200] hover:underline">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="text-[#F9A200] hover:underline">
                  Contact Us
                </Link>
              </li>
              <li>
                <a href="/console" className="text-[#F9A200] hover:underline">
                  Console
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">Legal</h2>
            <ul className="list-disc list-inside space-y-2 text-lg">
              <li>
                <Link to="/privacy-policy" className="text-[#F9A200] hover:underline">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-[#F9A200] hover:underline">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/license" className="text-[#F9A200] hover:underline">
                  License
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl league-spartan-bold text-black mb-4">Get in Touch</h2>
            <p className="text-lg">
              <Link to="/contact-us" className="text-[#F9A200] hover:underline">
                Contact Us
              </Link>{' '}
              for inquiries or support. We'd love to hear from you!
            </p>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
