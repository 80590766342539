import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';
import InnovationPanaImg from 'assets/innovation-pana.svg';
import VerdensLogo from 'assets/clients/verdens.svg';
import FrontiersLogo from 'assets/clients/frontiers.svg';
import WluLogo from 'assets/clients/wlu.svg';
import MedisawaLogo from 'assets/clients/medisawa.svg';
import JasttechLogo from 'assets/clients/jasttech.svg';
import AfarmLogo from 'assets/clients/afarm.svg';
import ScrumBoardBro from 'assets/scrum-board-bro.svg';
import PairProgrammingBro from 'assets/pair-programming-bro.svg';
import Step1Img from 'assets/step-1.svg';
import Step2Img from 'assets/step-2.svg';
import Step3Img from 'assets/step-3.svg';
import { Link } from 'react-router-dom';

export const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-64">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-start">
            <div className="text-4xl league-spartan-extra-bold md:text-8xl text-[#F9A200]">
              Innovating.
            </div>
            <div className="text-4xl league-spartan-bold mb-3">For a Safer, Smarter Future</div>
            <Link
              to="/request-quote"
              className="text-[#F9A200] border-b-4 league-spartan-extra-bold text-lg border-[#F9A200] bg-black p-2 px-12 py-3"
            >
              Request Quote
            </Link>
          </div>
          <div className="flex flex-row justify-center md:justify-end">
            <img src={InnovationPanaImg} alt="" className="h-64 md:h-[24vw]" />
          </div>
        </div>
      </div>

      <div className="p-8 px-8 md:px-64 flex flex-col items-end justify-end">
        <div className="text-[#F9A200] text-xl md:text-3xl league-spartan-extra-bold bg-black p-1 w-fit">
          Custom Tailored Solutions
        </div>
        <div className="text-xl text-black">
          We build systems designed exactly to our clients’ needs.
        </div>
      </div>

      <div className="p-12 px-8 md:px-64 bg-black flex flex-col items-end justify-end">
        <div className="bg-[#F9A200] w-fit p-2 text-black px-8 league-spartan-extra-bold mb-4">
          Our Clients
        </div>
        <div className="my-8 md:w-1/3 text-4xl md:text-4xl text-white league-spartan-bold text-end">
          We Are Dedicated To Service
        </div>
        <div className="text-white md:w-1/2 text-end text-xl mb-12">
          We are Dedicated to developing technologies that will exceed client expectations. We do
          our best to keep a smile on your face!
        </div>
        <div className="grid grid-cols-6 items-center gap-4">
          <img src={VerdensLogo} alt="" className="h-10" />
          <img src={FrontiersLogo} alt="" className="h-10" />
          <img src={WluLogo} alt="" className="h-10" />
          <img src={MedisawaLogo} alt="" className="h-10" />
          <img src={JasttechLogo} alt="" className="h-10" />
          <img src={AfarmLogo} alt="" className="h-10" />
        </div>
      </div>

      <div className="p-12 px-8 md:px-64 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
        <div className="">
          <img src={ScrumBoardBro} alt="" className="w-96" />
        </div>
        <div className="flex flex-col items-end justify-end">
          <div className="bg-black mb-3 p-2 px-6 text-[#F9A200] w-fit league-spartan-extra-bold">
            Our Approach
          </div>
          <div className="text-end mb-4 text-4xl md:text-4xl league-spartan-bold">
            Thorough Planning, Heartfelt Development
          </div>
          <div className="text-xl text-end">
            We believe in meticulous planning and best development practices to ensure project
            sustainability. Every project is thoroughly tested before delivery.
          </div>
        </div>
      </div>

      <div className="p-12 px-8 md:px-64 grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
        <div className="flex flex-col items-start justify-start">
          <div className="bg-black mb-3 p-2 px-6 text-[#F9A200] w-fit league-spartan-extra-bold">
            Our Teams
          </div>
          <div className="text-start mb-4 text-4xl md:text-4xl league-spartan-bold">
            Cohesive Teams of Designers, developers & cybersecurity experts.
          </div>
          <div className="text-xl text-start mb-4">
            Our teams composed of mostly remote creative designers, skilled engineers, and
            insightful analysts. We are committed to delivering high-quality work swiftly and
            efficiently.
          </div>
          <button className="text-[#F9A200] border-b-4 league-spartan-extra-bold text-lg border-[#F9A200] bg-black p-2 px-12">
            Visit Ashita Teams
          </button>
        </div>
        <div className="flex flex-row items-end justify-end">
          <img src={PairProgrammingBro} alt="" className="w-96" />
        </div>
      </div>

      <div className="p-12 px-8 md:px-64 bg-black flex flex-col items-end justify-end">
        <div className="bg-[#F9A200] w-fit p-2 text-white league-spartan-extra-bold mb-4">
          Our Workflow
        </div>
        <div className="md:w-2/3 text-2xl md:text-4xl text-white league-spartan-extra-bold text-end">
          We have a workflow that allows the job to be delivered well.
        </div>

        <div className="grid grid-cols-1 md:items-center md:grid-cols-2 gap-12">
          <div className="">
            <div className="uppercase text-white league-spartan-bold mx-3 mb-2">Step 01</div>
            <div className="text-4xl text-white league-spartan-extra-bold mb-6">
              Let's talk about your business's problems first
            </div>
            <div className="text-white text-xl mb-5 mt-4">
              After submitting the quote form, we will review the data, then we will contact you.
              You can discuss with our team regarding your problem and find a solution to that
              problem.
            </div>
            <div className="text-white text-xl">
              In this step, you will discuss what application to build.
            </div>
          </div>
          <div className="">
            <img src={Step1Img} alt="" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:items-center md:grid-cols-2 gap-12 my-12">
          <div className="">
            <img src={Step2Img} alt="" />
          </div>
          <div className="flex flex-col items-end justify-end">
            <div className="uppercase text-white league-spartan-bold mx-3 mb-2">Step 02</div>
            <div className="text-4xl text-white league-spartan-extra-bold mb-6 text-end">
              Doing planning, design and development until everything is finished
            </div>
            <div className="text-white text-xl mb-5 mt-4 text-end">
              When everything is agreed upon, our team will make plans related to the application
              that will be created. Starting from analysis, design, to development.
            </div>
            <div className="text-white text-xl">
              In this step, the application is 100% complete.
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:items-center md:grid-cols-2 gap-12 my-12">
          <div className="">
            <div className="uppercase text-white league-spartan-bold mx-3 mb-2">Step 03</div>
            <div className="text-4xl text-white league-spartan-extra-bold mb-6">
              The project is complete and we ship all the project assets, and access to the server
            </div>
            <div className="text-white text-xl mb-5 mt-4">
              We will be responsible for delivering all the project assets to you, don't worry.
              These assets include, design files, source code, server access, and so on.
            </div>
            <div className="text-white text-xl">
              In this step, everything is done and the contract ends.
            </div>
          </div>
          <div className="">
            <img src={Step3Img} alt="" />
          </div>
        </div>
      </div>

      <div className="p-12 px-8 md:px-56 bg-[#F9A200] border-b-4 border-black">
        <div className="bg-black w-fit p-2 text-white league-spartan-extra-bold mb-4">
          Request Quotation
        </div>
        <div className="text-2xl md:text-5xl league-spartan-extra-bold text-white">
          We've prepared everything, it's time for you to tell us the{' '}
          <span className="text-black">problem</span>. We can help you solve it at an affordable
          price.
        </div>
        <div className="my-8 flex flex-col md:flex-row md:items-center md:space-x-2">
          <Link
            to="/request-quote"
            className="text-[#F9A200] border-b-4 league-spartan-extra-bold text-lg border-white bg-black p-2 px-12 mb-3 md:mb-0"
          >
            Request Quote
          </Link>
          {/* <Link
            to="/ask-us"
            className="text-black border-b-4 league-spartan-extra-bold text-lg border-black bg-white p-2 px-12"
          >
            Ask Us
          </Link> */}
        </div>
      </div>
    </WebLayout>
  );
};
