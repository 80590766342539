import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-start">
            <div className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
              Privacy <span className="text-[#F9A200]"> Policy.</span>
            </div>
            <div className="text-3xl league-spartan-bold mb-3">Your Privacy Matters to Us</div>
            <div className="text-xl">
              At Ashita Creatives, we prioritize the protection of your personal data. This policy
              outlines how we collect, use, and safeguard your information.
            </div>
          </div>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">
            1. Information We Collect
          </h2>
          <p className="text-lg mb-6">We may collect the following types of information:</p>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>
              Personal Information: Name, email address, phone number, and billing details when you
              interact with our services.
            </li>
            <li>
              Technical Information: IP address, browser type, and device information when you visit
              our website.
            </li>
            <li>Usage Data: Pages visited, time spent, and actions taken on our platform.</li>
          </ul>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">
            2. How We Use Your Information
          </h2>
          <p className="text-lg">We use the information collected to:</p>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>Provide, operate, and improve our services.</li>
            <li>Process transactions and send updates about your orders or projects.</li>
            <li>Send promotional communications, where permitted by law.</li>
            <li>Enhance the security and functionality of our website.</li>
          </ul>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">
            3. Sharing of Information
          </h2>
          <p className="text-lg">
            We do not sell or share your personal information with third parties except:
          </p>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>To comply with legal obligations or respond to lawful requests.</li>
            <li>
              With trusted partners who assist in delivering our services, under strict
              confidentiality agreements.
            </li>
            <li>During a business transfer, merger, or acquisition.</li>
          </ul>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">4. Data Security</h2>
          <p className="text-lg">
            We implement robust measures to protect your information, including encryption and
            regular security audits. However, no system is completely secure, and we cannot
            guarantee absolute data protection.
          </p>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">5. Your Rights</h2>
          <p className="text-lg">You have the right to:</p>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>Access, update, or delete your personal information.</li>
            <li>Opt out of receiving promotional communications.</li>
            <li>Withdraw consent where processing is based on your consent.</li>
          </ul>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">
            6. Changes to This Policy
          </h2>
          <p className="text-lg">
            We may update this privacy policy periodically. Changes will be communicated via our
            website, and we encourage you to review it regularly.
          </p>
        </div>
        <div className="mt-10">
          <h2 className="text-3xl league-spartan-bold text-black mb-4">7. Contact Us</h2>
          <p className="text-lg">
            If you have any questions about this privacy policy or our practices, please contact us
            at:
          </p>
          <p className="text-lg">
            Email:{' '}
            <a href="mailto:ashitacreatives@gmail.com" className="text-[#F9A200] hover:underline">
              ashitacreatives@gmail.com
            </a>{' '}
            <br />
            Phone: +256 782 692271 <br />
          </p>

          <div className="mt-12">Last Updated: 26th November, 2024</div>
        </div>
      </div>
    </WebLayout>
  );
};
