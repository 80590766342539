import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';
import ProductHuntRafiki from 'assets/product-hunt-rafiki.svg';
import TebezaApp from 'assets/tebeza.png';
import RentManApp from 'assets/rentman.png';

export const OurProducts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-start">
            <div className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
              Our <span className="text-[#F9A200]"> Products.</span>
            </div>
            <div className="text-3xl league-spartan-bold mb-3">
              We build & maintain our products.
            </div>
            <div className="text-xl">
              Feel free to try out any of our products and leave a review. Your input helps us make
              our products better for your use.
            </div>
          </div>
          <div className="flex flex-row justify-center md:justify-end">
            <img src={ProductHuntRafiki} alt="" className="h-64 md:h-[24vw]" />
          </div>
        </div>
      </div>
      <div className="bg-[#FFFEF6]">
        <div className="p-8 md:px-44 items-center grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="p-12">
            <div className="mb-3 bg-[#F8DC3D] text-white p-1 px-3 text-3xl w-fit league-spartan-extra-bold">
              Tebeza
            </div>
            <div className="text-black text-xl my-8">
              A place for creatives to express themselves. Write a book, record an audio book, write
              a poem or open an art gallery. Collect donations.
            </div>
            <button className="text-xl border-b-2 border-black pb-2">Join Beta Testing</button>
          </div>

          <div className="flex flex-row items-end justify-end">
            <img src={TebezaApp} alt="" className="mx-24 h-64 md:h-[24vw]" />
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="p-8 md:px-48 items-center grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-row items-start justify-start">
            <img src={RentManApp} alt="" className="mx-12 h-64 md:h-[24vw]" />
          </div>
          <div className="p-12 flex flex-col items-end justify-end">
            <div className="mb-3 bg-[#8700F3] text-white p-1 px-3 text-3xl w-fit league-spartan-extra-bold">
              RentMan
            </div>
            <div className="text-end text-white text-xl my-8">
              Are you a landlord? Create an account with RentMan and let your tenants pay with ease.
              RentMan supports <span className="league-spartan-extra-bold">Mobile Money</span>,{' '}
              <span className="league-spartan-extra-bold">Airtel Money</span> &{' '}
              <span className="league-spartan-extra-bold">Card</span>.
            </div>
            <button className="text-xl border-b-2 border-white text-white pb-2">
              Join Beta Testing
            </button>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
