import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';
import ContactBanner from 'assets/contact-banner.svg';
import WhatsappImg from 'assets/whatsapp.png';
import { AtSign, PhoneCall } from 'react-feather';

export const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="border-b-4 border-black grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-start">
            <div className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
              Contact <span className="text-[#F9A200]"> Us.</span>
            </div>
            <div className="text-3xl league-spartan-bold mb-3">
              We love receiving messages from you, we are waiting for it.
            </div>
            <div className="text-xl mb-3">
              Send your message and someone will reach out within{' '}
              <span className="league-spartan-extra-bold">3 - 4 hours</span>. Don’t hesitate to call
              in case you need urgent attention.
            </div>
            <div className="p-4 px-8">
              <div className="flex flex-row items-center space-x-2 mb-2">
                <PhoneCall className="h-6 text-[#F9A200]" />
                <span className="league-spartan-extra-bold">+256 773 558 907</span>
              </div>
              <div className="flex flex-row items-center space-x-2 mb-2">
                <AtSign className="h-6 text-[#F9A200]" />
                <span className="league-spartan-extra-bold">ashitacreatives@gmail.com</span>
              </div>
              <div className="flex flex-row items-center space-x-2 mb-2">
                <img src={WhatsappImg} alt="whatsapp" className="h-6" />
                <span className="league-spartan-extra-bold">+256 782 692271</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center md:justify-end mr-12">
            <img src={ContactBanner} alt="" className="h-64 md:h-[28vw]" />
          </div>
        </div>
      </div>
      <div className="p-8 px-8 md:px-56">
        <div className="bg-black mb-3 p-2 px-6 text-[#F9A200] w-fit league-spartan-extra-bold">
          Send us a message
        </div>
        <div className="text-xl w-2/3 mb-5">
          If you have any inquiries or concerns, describe your inquiry with as much detail as you
          can provide.
        </div>
        <div className="text-xl text-gray-500 flex flex-row items-start justify-start my-12">
          <div className="text-center">
            <em>
              Contact form is currently unavailable. You can send an email to{' '}
              <a
                href="mailto:ashitacreatives@gmail.com"
                className="text-[#F9A200] underline league-spartan-extra-bold"
              >
                ashitacreatives@gmail.com
              </a>{' '}
            </em>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
