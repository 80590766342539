import {
  AboutUs,
  Console,
  ContactUs,
  Landing,
  License,
  OurProducts,
  PrivacyPolicy,
  RequestQuote,
  SiteMap,
  TermsOfService,
} from 'features/misc';
import { useRoutes } from 'react-router-dom';

export const AppRoutes = () => {
  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/request-quote', element: <RequestQuote /> },
    { path: '/our-products', element: <OurProducts /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/console', element: <Console /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-service', element: <TermsOfService /> },
    { path: '/license', element: <License /> },
    { path: '/sitemap', element: <SiteMap /> },
  ];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
