import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';
import QuoteBannerImg from 'assets/quote-banner.svg';
import { AtSign } from 'react-feather';

export const RequestQuote = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-64">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-start">
            <div className="text-4xl league-spartan-extra-bold md:text-6xl text-black">
              Request <span className="text-[#F9A200]">Quote.</span>
            </div>
            <div className="text-xl md:text-2xl league-spartan-bold">
              Tell us about your business problem, and we come up with a few solutions.
            </div>
            <div className="league-spartan-light my-3">
              Please note that our pricing on the website is an estimate. You need to contact our
              operations manager and schedule a call to discuss your needs.
            </div>
            <div className="bg-[#FFF8EC] p-4 flex flex-row items-center space-x-2">
              <div className="">
                <AtSign className="text-[#F9A200] font-bold" />
              </div>
              <div className="league-spartan-extra-bold">
                <div className="text-[#F9A200]">Operations Manager</div>
                <div className="league-spartan-bold text-md md:text-xl">
                  alinda@ashitacreatives.com
                </div>
                <div className="league-spartan-light">+256 773 558 907</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end md:justify-end">
            <img src={QuoteBannerImg} alt="" className="h-64 md:h-[24vw] mb-2" />
            <div className="mx-auto">
              {' '}
              <button className="w-fit text-[#F9A200] border-b-4 league-spartan-extra-bold text-lg border-[#F9A200] bg-black p-2 px-12">
                Schedule Call
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="text-xl text-gray-500 flex flex-row items-center justify-center my-12">
        <div className="text-center">
          <em>
            Quotation form is currently unavailable. You can send an email to{' '}
            <a
              href="mailto:ashitacreatives@gmail.com"
              className="text-[#F9A200] underline league-spartan-extra-bold"
            >
              ashitacreatives@gmail.com
            </a>{' '}
          </em>
        </div>
      </div>
    </WebLayout>
  );
};
